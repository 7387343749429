import React from 'react';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { LandingPageImpl } from '../services/page/landing.service';
import { LandingPage } from '../types/landing';
import { Homepage } from '../types/homepage';
import PageLayout from '../page-layouts';
import { SEO } from '../components';
import NavBar from '../components/nav-bar/nav-bar';
import FooterSection from '../components/footer';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import InfoSection from '../components/solar-tech/info-section';
import { Block } from 'baseui/block';
import theme from '../page-layouts/theme';
import Quotation from '../components/projects/quotation';
import { GetGuideSection } from '../components/get-guide-section/get-guide-section';
import GoSolar from '../components/projects/go-solar';
import AwardsSection from '../components/reviews-and-awards/reviews-and-awards';

declare type LandingProps = {
  serverData: any;
};

const Landing: React.FC<LandingProps> = ({ serverData }) => {
  const landingPageData = serverData.landingPageData as LandingPage;
  const homePageData = serverData.homePageData as Homepage;
  return (
    <PageLayout>
      <Block
        backgroundColor={theme.colors.backgroundPrimary}
        width="100%"
        overflow={'hidden'}
      >
        {landingPageData.data.attributes.seo && <SEO data={landingPageData.data.attributes.seo} />}
        <NavBar data={homePageData.data.attributes.Header} position="fixed" />
        <CommonBanner banner={landingPageData.data.attributes.Banner} />
        <Quotation data={homePageData.data.attributes.Quote} />
        <Block paddingTop="scale3200">
          {landingPageData.data.attributes.InfoSections.map(item => (
            <InfoSection data={item} />
          ))}
        </Block>
        <GoSolar data={landingPageData.data.attributes.Installation} />
        <AwardsSection
          awards={homePageData.data.attributes.Awards}
        />
        <GetGuideSection
          data={homePageData.data.attributes.GetSolarGuideSection}
        />
        <FooterSection data={homePageData.data.attributes.Footer} />
      </Block>
    </PageLayout>
  );
};

export default Landing;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new LandingPageImpl();

  const res = await Promise.all([
    serviceObject.getLandingPageData(),
    homeServiceObject.getHomePage(),
  ]);

  return {
    props: {
      landingPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

